<template>
  <v-card class="pa-0" rounded="md">
    <v-card-title class="background" v-if="account.logo">
      <v-row class="px-3" justify="start">
        <v-sheet class="overflow-hidden" width="90">
          <v-responsive class="align-center" :aspect-ratio="1/1">
            <v-sheet class="px-1" color="white" width="90">
              <v-img :src="account.logo.split(/[?#]/)[0]" width="100%" />
            </v-sheet>
          </v-responsive>
        </v-sheet>
      </v-row>
    </v-card-title>
    <v-card-subtitle class="background">
      <v-row>
        <v-col class="pb-0" cols="12">
          <span class="d-block subtitle-1 font-weight-medium mt-1">{{instance.document.name}} Nº {{instance.number}} de {{account.name}}</span>
          <span class="d-block body-2 text--primary font-weight-regular">para {{instance.documents.customer.name}}</span>
          <span class="d-block body-1 font-weight-medium mt-3">{{instance.total | currency(instance.documents.currency)}} {{instance.documents.currency}} del {{instance.date | date}}</span>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="py-0">
      <v-row>
        <v-col class="pa-0 pb-2" cols="12">
          <v-textarea class="elevation-0" v-model="messageBody" @input="updateMessage($event)" :placeholder="placeholder" auto-grow solo flat hide-details />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ReminderViewMixin from '@/mixins/ReminderViewMixin'
export default {
  mixins: [
    ReminderViewMixin
  ]
}
</script>